import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M10.38 14.51H7.7c-.44 0-.85-.21-1.11-.57-.25-.34-.31-.76-.19-1.16.347-1.06 1.193-1.638 1.948-2.155l.022-.015c.8-.54 1.25-.88 1.25-1.46a.939.939 0 1 0-1.88 0c0 .41-.34.75-.75.75s-.75-.34-.75-.75c0-1.34 1.09-2.44 2.44-2.44 1.35 0 2.44 1.09 2.44 2.44 0 1.407-1.055 2.127-1.904 2.706l-.006.004c-.53.36-1.03.7-1.28 1.15h2.44c.41 0 .75.34.75.75s-.33.75-.74.75Z" }, null, -1),
    _createElementVNode("path", {
      d: "M15.29 13.76c0 .41.34.75.75.75.42 0 .75-.33.75-.74v-.69H17c.41 0 .75-.34.75-.75s-.34-.75-.75-.75h-.22V8.06c.01-.6-.39-1.12-.96-1.29a1.34 1.34 0 0 0-1.5.58c-.72 1.16-1.51 2.49-2.19 3.66-.25.42-.25.95 0 1.38.25.42.7.68 1.19.68h1.97v.69Zm-1.75-2.19c.55-.94 1.16-1.97 1.75-2.93v2.93h-1.75Z",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      d: "M12 22.75c-.7 0-1.41-.27-1.94-.8l-1.71-1.69c-.43-.42-.99-.65-1.59-.65H6c-2.07 0-3.75-1.67-3.75-3.72V4.98c0-2.05 1.68-3.72 3.75-3.72h12c2.07 0 3.75 1.67 3.75 3.72v10.91c0 2.05-1.68 3.72-3.75 3.72h-.76c-.6 0-1.17.23-1.59.65l-1.71 1.69c-.53.53-1.24.8-1.94.8Zm-6-20c-1.24 0-2.25 1-2.25 2.22v10.91c0 1.23 1.01 2.22 2.25 2.22h.76c1 0 1.94.39 2.65 1.09l1.71 1.69c.49.48 1.28.48 1.77 0l1.71-1.69c.71-.7 1.65-1.09 2.65-1.09H18c1.24 0 2.25-1 2.25-2.22V4.97c0-1.23-1.01-2.22-2.25-2.22H6Z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }