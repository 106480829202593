import AppImgNotificationRed from "~/components/img/objectView/AppImgNotificationRed.vue";
import AppImgNotificationGreen from "~/components/img/objectView/AppImgNotificationGreen.vue";
import AppImgNotificationYellow from "~/components/img/objectView/AppImgNotificationYellow.vue";

export const useNotificationStore = defineStore('notification_store', {
    state: () => (
        {
            notification: [],
        }
    ),
    getters: {
        getNotification: (state) => {
            return state.notification;
        },
    },

    actions: {
        setNotification(node) {
            this.notification.push(node);
        },
        popNotification(node) {
            this.notification.unshift(node)
        },
        reset() {
            this.notification = [];
        },
        deleteNotification(index) {
             this.notification.splice(index, 1)
        },
        deleteAllNotifications() {
            this.notification.splice(0, this.notification.length)
        }
    },
    persist: false,
});