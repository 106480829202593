<template>
    <svg viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
        <path d="M12 12a5 5 0 1 0 0-10 5 5 0 0 0 0 10ZM3.41 22c0-3.87 3.85-7 8.59-7 .96 0 1.89.13 2.76.37"/>
        <path d="M22 18c0 .32-.04.63-.12.93-.09.4-.25.79-.46 1.13A3.97 3.97 0 0 1 18 22a3.92 3.92 0 0 1-2.66-1.03c-.3-.26-.56-.57-.76-.91A3.92 3.92 0 0 1 14 18a3.995 3.995 0 0 1 4-4c1.18 0 2.25.51 2.97 1.33.64.71 1.03 1.65 1.03 2.67Z" stroke-miterlimit="10"/>
        <g stroke-miterlimit="10">
            <path d="M19.49 17.98h-2.98M18 16.52v2.99"/>
        </g>
    </g>
</svg>
</template>