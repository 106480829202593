import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M12 12.11c-.41 0-.75-.34-.75-.75v-.21c0-1.146.83-1.716 1.158-1.942l.012-.008c.37-.25.49-.42.49-.68 0-.5-.41-.91-.91-.91s-.91.41-.91.91c0 .41-.34.75-.75.75s-.75-.34-.75-.75c0-1.33 1.08-2.41 2.41-2.41s2.41 1.08 2.41 2.41c0 1.14-.84 1.71-1.15 1.92-.39.26-.51.43-.51.71v.21c0 .42-.34.75-.75.75ZM11.25 13.85a.749.749 0 1 0 1.498.002.749.749 0 0 0-1.498-.002Z" }, null, -1),
    _createElementVNode("path", {
      d: "M8 22.32c-.28 0-.57-.07-.83-.21-.57-.3-.92-.9-.92-1.54v-1.42c-3.02-.31-5-2.53-5-5.71v-6C1.25 4 3.56 1.69 7 1.69h10c3.44 0 5.75 2.31 5.75 5.75v6c0 3.44-2.31 5.75-5.75 5.75h-3.77l-4.26 2.84c-.29.19-.63.29-.97.29ZM7 3.18c-2.58 0-4.25 1.67-4.25 4.25v6c0 2.58 1.67 4.25 4.25 4.25.41 0 .75.34.75.75v2.13c0 .13.08.19.13.22s.15.06.26-.01l4.45-2.96c.12-.08.27-.13.42-.13h4c2.58 0 4.25-1.67 4.25-4.25v-6c0-2.58-1.67-4.25-4.25-4.25H7Z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }