<template>
    <svg viewBox="0 0 42 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.753 24.065 38.604 3.942l1.199 1.216 1.2 1.215-19.851 20.123-1.2-1.215-1.199-1.216Z" fill="url(#a)"/>
    <path d="M1.404 23.76 39.012 3.638l.79 1.52.792 1.52L2.987 26.8l-.792-1.52-.791-1.52Z" fill="url(#b)"/>
    <path d="m27.09 2.113-5.487 23.563-1.65-.395-1.65-.395 5.486-23.563 1.65.395 1.65.395Z" fill="url(#c)"/>
    <path d="M2.196 27c.936 0 1.695-.77 1.695-1.72 0-.949-.76-1.718-1.695-1.718-.937 0-1.696.77-1.696 1.719 0 .949.76 1.718 1.696 1.718ZM19.953 27c.936 0 1.695-.77 1.695-1.72 0-.949-.759-1.718-1.695-1.718-.937 0-1.696.77-1.696 1.719 0 .949.76 1.718 1.696 1.718Z" fill="#FF4848"/>
    <path d="M25.44 3.437c.936 0 1.695-.77 1.695-1.719C27.135.768 26.376 0 25.44 0c-.937 0-1.696.77-1.696 1.72 0 .948.76 1.718 1.696 1.718Z" fill="#4F46E5"/>
    <path d="M.997 24.065 24.247.495l1.2 1.216 1.198 1.216-23.25 23.569-1.2-1.215-1.198-1.216Z" fill="url(#d)"/>
    <path d="M39.804 6.875c.937 0 1.696-.77 1.696-1.719 0-.949-.76-1.718-1.696-1.718-.936 0-1.695.77-1.695 1.718 0 .95.759 1.72 1.695 1.72Z" fill="#4F46E5"/>
    <defs>
        <linearGradient id="a" x1="38.587" y1="6.399" x2="27.376" y2="17.076" gradientUnits="userSpaceOnUse">
            <stop stop-color="#536DFE"/>
            <stop offset="1" stop-color="#FF4848"/>
        </linearGradient>
        <linearGradient id="b" x1="23.047" y1="14.205" x2="1.28" y2="25.216" gradientUnits="userSpaceOnUse">
            <stop stop-color="#536DFE"/>
            <stop offset="1" stop-color="#FF4848"/>
        </linearGradient>
        <linearGradient id="c" x1="25.891" y1="1.336" x2="20.107" y2="23.123" gradientUnits="userSpaceOnUse">
            <stop stop-color="#536DFE"/>
            <stop offset="1" stop-color="#FF4848"/>
        </linearGradient>
        <linearGradient id="d" x1="26.302" y1=".843" x2="9.728" y2="16.055" gradientUnits="userSpaceOnUse">
            <stop offset=".167" stop-color="#536DFE"/>
            <stop offset="1" stop-color="#FF4848"/>
        </linearGradient>
    </defs>
</svg>
</template>